@import "../../utils/breakpoints";

.programs {
    position: relative;
    padding: 0 24px;
    margin-top: 72px;
    color: white;

    @include breakpoints(md) {
        margin-top: 120px;
        padding: 0 32px;
    }

    @include breakpoints(lg) {
        margin-top: 132px;
        padding: 0 48px;
    }

    @include breakpoints(xl) {
        margin-top: 132px;
        padding: 0 136px;
    }

    &__container {
        position: relative;
        z-index: 3;
    }

    &__image {
        position: absolute;
        display: none;
        z-index: 2;

        @include breakpoints(md) {
            display: block;
            top: -264px;
            right: -500px;
            width: 801px;
            height: 668px;
        }

        @include breakpoints(lg) {
            width: 837px;
            height: 697px;
        }

        @include breakpoints(xl) {
            width: 918px;
            height: 857px;
        }

        & > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__title {
        font-family: SBSansDisplay;
        font-size: 42px;
        line-height: 42px;
        font-weight: 500;
        letter-spacing: -0.3px;

        @include breakpoints(md) {
            font-size: 62px;
            line-height: 76px;
            font-weight: 500;
        }

        @include breakpoints(lg) {
            font-size: 68px;
            line-height: 76px;
            font-weight: 500;
        }
    }

    &__halls {
        display: flex;
        flex-direction: row;
        margin-top: 32px;
        column-gap: 12px;

        @include breakpoints(md) {
            margin-top: 56px;
            column-gap: 16px;
            justify-content: normal;
        }

        @include breakpoints(lg) {
            margin-top: 61px;
        }
    }

    &__hall {
        color: white;
        background: transparent;
        border: 1px solid rgba(255, 255, 255, 0.32);
        outline: none;
        cursor: pointer;
        font-family: SBSansDisplay;
        font-size: 14px;
        line-height: 34px;
        font-weight: 500;
        padding: 4px 14px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        &--active {
            border: none;
            background: linear-gradient(90deg, rgb(49, 194, 167), rgb(17, 100, 192));
        }

        @include breakpoints(md) {
            font-size: 26px;
            line-height: 34px;
            font-weight: 500;
            padding: 14px 32px;
        }
    }

    &__topics {
        display: flex;
        flex-direction: column;
        row-gap: 32px;
        margin-top: 40px;

        @include breakpoints(md) {
            margin-top: 66px;
            row-gap: 44px;
        }

        @include breakpoints(lg) {
            margin-top: 61px;
        }
    }

    &__topic {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid rgba(255, 255, 255, .4);
        padding-bottom: 32px;

        @include breakpoints(md) {
            padding-bottom: 44px;
        }

        @include breakpoints(lg) {
            column-gap: 129px;
            flex-direction: row;
            align-items: flex-start;
        }

        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }
    }

    &__topicTime {
        font-family: SBSansDisplay;
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
        letter-spacing: -0.3px;
        width: 155px;

        @include breakpoints(md) {
            font-size: 26px;
            line-height: 34px;
            flex-shrink: 0;
        }
    }

    &__topicTitle {
        font-family: SBSansText;
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
        letter-spacing: -0.3px;
        margin-top: 16px;

        @include breakpoints(md) {
            margin-top: 32px;
            font-size: 26px;
            line-height: 34px;
        }

        @include breakpoints(lg) {
            margin-top: 0;
        }
    }

    &__topicDescription {
        font-family: SBSansText;
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
        letter-spacing: -0.3px;
        margin-top: 8px;
        opacity: .64;

        @include breakpoints(md) {
            margin-top: 14px;
            font-size: 18px;
            line-height: 28px;
        }
    }

    &__speakers {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        margin-top: 24px;

        @include breakpoints(md) {
            margin-top: 32px;
        }

        @include breakpoints(xl) {
            flex-direction: row;
            flex-wrap: wrap-reverse;
            column-gap: 65px;
        }
    }

    &__speaker {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        column-gap: 10px;

        @include breakpoints(xl) {
            flex-shrink: 0;
            width: 390px;
        }
    }

    &__speakerImage {
        width: 69px;
        height: 69px;
        border-radius: 50%;
        overflow: hidden;
        flex-shrink: 0;

        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__speakerInfo {
        display: flex;
        flex-direction: column;
    }

    &__speakerName {
        font-family: SBSansText;
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        letter-spacing: -0.3px;
    }

    &__speakerPosition {
        font-family: SBSansText;
        font-size: 13px;
        line-height: 18px;
        font-weight: 300;
        letter-spacing: -0.3px;
        color: rgb(129, 135, 142);
        margin-top: 4px;
    }

    &__speakerPlace {
        font-family: SBSansText;
        font-size: 13px;
        line-height: 18px;
        font-weight: 300;
        letter-spacing: -0.3px;
        color: rgb(129, 135, 142);
        margin-top: 4px;
        padding-left: 7px;
        position: relative;

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 10px;
            content: '';
            display: block;
            height: 100%;
            width: 3px;
            background: rgb(214, 220, 226);
        }
    }

    &__speakerLead {
        font-family: SBSansText;
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        letter-spacing: -0.3px;
        margin-top: 9px;
        border: 1px solid rgba(207, 212, 221, 0.7);
        border-radius: 14px;
        padding: 7px 8px;
        display: flex;
        align-items: center;
        width: fit-content;
    }

    &__speakerIcon {
        width: 16px;
        height: 16px;
        margin-right: 7px;

        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__btn {
        text-decoration: none;
        color: white;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        background: linear-gradient(90deg, rgb(49, 194, 167), rgb(17, 100, 192));
        border-radius: 12px;
        padding: 11px 0;

        margin-top: 48px;
        font-family: SBSansDisplay;
        font-size: 16px;
        line-height: 34px;
        font-weight: 500;
        letter-spacing: -0.3px;

        @include breakpoints(md) {
            font-size: 26px;
            line-height: 34px;
            font-weight: 500;
            margin-top: 68px;
            padding: 21px 0;
        }

        @include breakpoints(lg) {
            margin-top: 88px;
        }

        @include breakpoints(xl) {
            padding: 24px 0;
            margin-top: 92px;
        }
    }
}
