@import "../../utils/breakpoints";

.video {
    position: relative;
    z-index: 3;
    padding: 0 24px;
    margin-top: 72px;
    color: white;

    @include breakpoints(md) {
        margin-top: 120px;
        padding: 0 19px;
    }

    @include breakpoints(lg) {
        margin-top: 132px;
        padding: 0 48px;
    }

    @include breakpoints(xl) {
        margin-top: 132px;
        padding: 0 136px;
    }

    &__title {
        font-family: SBSansDisplay;
        font-size: 42px;
        line-height: 42px;
        font-weight: 500;
        letter-spacing: 0;

        @include breakpoints(md) {
            font-size: 62px;
            line-height: 76px;
        }

        @include breakpoints(lg) {
            font-size: 68px;
            line-height: 76px;
        }
    }

    &__items {
        margin-top: 32px;
        display: flex;
        column-gap: 12px;

        @include breakpoints(md) {
            margin-top: 56px;
            column-gap: 16px;
        }

        &_item {
            padding: 4px 24px;
            color: white;
            background: transparent;
            border-radius: 12px;
            border: 1px solid rgba(255, 255, 255, 0.32);
            outline: none;
            font-family: SBSansDisplay;
            font-size: 14px;
            line-height: 34px;
            font-weight: 500;
            letter-spacing: -0.3px;
            cursor: pointer;

            @include breakpoints(md) {
                font-size: 26px;
                line-height: 34px;
                padding: 14px 32px;
            }

            &--active {
                border: none;
                background: linear-gradient(90deg, rgb(49, 194, 167), rgb(17, 100, 192));
            }
        }
    }

    &__frame {
        align-items: center;
        justify-content: center;
        position: relative;
        margin: 24px 0 0;
        max-width: 375px;
        width: 100%;
        height: 211px;
        border-radius: 20px;
        overflow: hidden;
        display: flex;
        

        @include breakpoints(md) {
            width: 100%;
            height: 360px;
            max-width: none;
            margin: 32px 0 0 0;
        }

        @include breakpoints(lg) {
            height: 472px;
        }

        @include breakpoints(xl) {
            height: 594px;
        }
    }

    &__videoIcon {
        position: absolute;
        width: 27px;
        height: 27px;

        @include breakpoints(md) {
            width: 60px;
            height: 60px;
        }

        @include breakpoints(lg) {
            width: 80px;
            height: 80px;
        }

        @include breakpoints(xl) {
            width: 100px;
            height: 100px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
