@import "../../utils/breakpoints";

.gradient {
  position: absolute;
  border-radius: 50%;
  z-index: 0;
  background: radial-gradient(rgba(82, 158, 227, 0.15), rgba(90, 176, 255, 0));

  &__top_left {
    z-index: 2;
    width: 677px;
    height: 677px;
    top: 104px;
    left: -297px;
  }

  &__top_right {
    z-index: 1;
    width: 657px;
    height: 658px;
    top: 504px;
    left: 47px;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @include breakpoints(md) {
    &__top_left {
      width: 1093px;
      height: 1093px;
      top: 243px;
      left: -546px;
    }

    &__top_right {
      width: 1501px;
      height: 1501px;
      top: 322px;
      left: 56px;
    }
  }

  @include breakpoints(lg) {
    &__top_left {
      width: 1753px;
      height: 1753px;
      top: -129px;
      left: -876px;
    }

    &__top_right {
      width: 1753px;
      height: 1753px;
      top: 128px;
      left: 138px;
    }
  }

  @include breakpoints(xl) {
    &__top_left {
      width: 1753px;
      height: 1753px;
      top: 68px;
      left: -870px;
    }

    &__top_right {
      width: 1753px;
      height: 1753px;
      top: 367px;
      left: 564px;
    }
  }
}
