@import "./utils/breakpoints.scss";

.sbergile_talks_2024 {
    position: relative;
    overflow: hidden;
    background: rgb(0, 0, 0);
}

.sbergile_talks_2024__conteiner {
    width: 100%;
    max-width: 375px;
    margin: 0 auto;

    @include breakpoints(md) {
        max-width: 768px;
    }

    @include breakpoints(lg) {
        max-width: 1024px;
    }

    @include breakpoints(xl) {
        max-width: 1440px;
    }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}