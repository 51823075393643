@import "../../utils/breakpoints";

.cookies {
  z-index: 666;
  position: fixed;
  bottom: 10px;
  left: 0;
  width: 100%;
  padding: 32px 24px 0;
  margin: 0 auto;

  //@include breakpoints(md) {
  //  padding: 48px 32px 0;
  //}
  //
  //@include breakpoints(lg) {
  //  padding: 48px 48px 0;
  //}
  //
  //@include breakpoints(xl) {
  //  padding: 48px 136px 0;
  //}

  &__banner {
    margin: 0 auto;
    background: rgb(40, 40, 40);
    box-sizing: border-box;
    border-radius: 12px;
    padding: 16px;
    max-width: 375px;

    @include breakpoints(md) {
      max-width: 768px;
      display: flex;
      align-items: center;
      column-gap: 25px;
    }

    @include breakpoints(lg) {
      padding: 29px 25px;
      max-width: 928px;
    }

    @include breakpoints(xl) {
      max-width: 1168px;
    }

    &_text {
      color: white;
      font-family: SBSansDisplay;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -2%;
      text-align: left;

      a {
        color: white;
      }
    }

    &_button {
      border: 1px solid rgb(255, 255, 255);
      border-radius: 12px;
      color: white;
      font-family: SBSansDisplay;
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      letter-spacing: 0;
      text-align: center;
      margin-top: 46px;
      cursor: pointer;
      padding: 15px 30px;
      width: fit-content;

      @include breakpoints(md) {
        margin-top: 0;
      }
    }
  }
}