@import "../../utils/breakpoints";

.speakers {
    position: relative;
    padding: 0 24px;
    margin-top: 72px;
    color: white;

    @include breakpoints(md) {
        margin-top: 120px;
        padding: 0 32px;
    }

    @include breakpoints(lg) {
        margin-top: 132px;
        padding: 0 48px;
    }

    @include breakpoints(xl) {
        margin-top: 132px;
        padding: 0 136px;
    }

    &__title {
        font-family: SBSansDisplay;
        font-size: 42px;
        line-height: 42px;
        font-weight: 500;

        @include breakpoints(md) {
            font-size: 62px;
            line-height: 76px;
            font-weight: 500;
        }

        @include breakpoints(lg) {
            font-size: 68px;
            line-height: 76px;
            font-weight: 500;
        }
    }

    &__items {
        display: flex;
        flex-direction: column;
        row-gap: 32px;
        margin-top: 32px;

        @include breakpoints(md) {
            margin-top: 56px;
            flex-direction: row;
            flex-wrap: wrap;
            column-gap: 24px;
            row-gap: 33px;
        }

        @include breakpoints(lg) {
            row-gap: 48px;
            column-gap: 32px;
        }
    }

    &__item {
        width: 100%;
        display: flex;
        flex-direction: column;
        max-width: 327px;
        height: 100%;
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        padding: 7px 16px;
        aspect-ratio: 327 / 352;

        @include breakpoints(md) {
            width: 340px;
            height: 352px;
        }

        @include breakpoints(lg) {
            width: 288px;
        }

        @include breakpoints(xl) {
            width: 268px;
        }

        &_load {
            width: 100%;
            max-width: 327px;
            border-radius: 20px;
            background-color: #14191f;
            height: 352px;
            position: relative;
            overflow: hidden;

            @include breakpoints(md) {
                width: 340px;
                height: 352px;
            }

            @include breakpoints(lg) {
                width: 288px;
            }

            @include breakpoints(xl) {
                width: 268px;
            }

            &::after {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                transform: translateX(-100%);
                background-image: linear-gradient(
                  90deg,
                  rgba(#fff, 0) 0,
                  rgba(#fff, 0.2) 20%,
                  rgba(#fff, 0.5) 60%,
                  rgba(#fff, 0)
                );
                animation: shimmer 5s infinite;
                content: '';
              }

              @keyframes shimmer {
                100% {
                  transform: translateX(100%);
                }
              }
        }
    }

    &__image {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        &:after {
            content: '';
            position: absolute;
            display: block;
            z-index: 4;
            left: 0;
            bottom: 0;
            right: 0;
            height: 50%;
            background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            z-index: 3;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: linear-gradient(rgba(18, 24, 47, 0.14), rgba(18, 24, 47, 0.2));
        }

        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__info {
        position: relative;
        z-index: 4;
        margin-top: auto;
        max-width: 238px;
    }

    &__name {
        font-family: SBSansDisplay;
        font-size: 22px;
        line-height: 28px;
        font-weight: 500;
        letter-spacing: -0.3px;

        @include breakpoints(md) {
            font-weight: 600;
        }
    }

    &__workPosition {
        margin-top: 5px;
        font-family: SBSansText;
        font-size: 13px;
        line-height: 20px;
        font-weight: 300;
        letter-spacing: -0.3px;
        color: rgba(255, 255, 255, 0.8);
    }

    &__workPlace {
        margin-top: 8px;
        font-family: SBSansText;
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        letter-spacing: -0.3px;
    }

    &__moreBtn {
        outline: none;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        margin-top: 48px;
        font-family: SBSansText;
        font-size: 18px;
        line-height: 28px;
        font-weight: 500;
        letter-spacing: -0.3px;
        background: transparent;
        border: 1px solid rgb(72, 72, 72);
        color: white;
        padding: 16px 0;
        cursor: pointer;

        @include breakpoints(md) {
            width: fit-content;
            display: block;
            padding: 16px 32px;
            margin: 47px auto 0;
        }
    }
}
