@import "../../utils/breakpoints";

.header {
    position: relative;
    z-index: 3;
    padding: 32px 24px 0;
    color: white;

    @include breakpoints(md) {
        padding: 48px 32px 0;
    }

    @include breakpoints(lg) {
        padding: 48px 48px 0;
    }

    @include breakpoints(xl) {
        padding: 48px 136px 0;
    }

    &__logo {
        width: 66px;
        height: 18px;

        @include breakpoints(md) {
            width: 116px;
            height: 32px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__bgTopImage {
        position: absolute;
        top: -43px;
        left: 192px;
        width: 329px;
        height: 307px;
        transform: rotate(-9deg);

        @include breakpoints(md) {
            top: 0;
            left: 463px;
            width: 735px;
            height: 695px;
            transform: rotate(0);
        }

        @include breakpoints(lg) {
            top: 0;
            left: 477px;
            width: 906px;
            height: 848px;
        }

        @include breakpoints(xl) {
            top: 0;
            left: 689px;
            width: 1108px;
            height: 1037px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__title {
        position: relative;
        z-index: 3;
        font-family: SBSansDisplay;
        margin-top: 75px;
        font-size: 38px;
        line-height: 38px;
        font-weight: 500;
        letter-spacing: 0;

        @include breakpoints(xs) {
            font-size: 48px;
            line-height: 48px;
        }

        @include breakpoints(md) {
            margin-top: 48px;
            font-size: 80px;
            line-height: 80px;
            max-width: 620px;
        }

        @include breakpoints(xl) {
            margin-top: 56px;
            font-size: 110px;
            line-height: 110px;
            font-weight: 500;
            letter-spacing: 0;
            max-width: 960px;
        }
    }

    &__subtitle {
        font-family: SBSansText;
        font-weight: 300;
        letter-spacing: -0.3px;
        margin-top: 16px;
        max-width: 324px;
        font-size: 16px;
        line-height: 24px;

        @include breakpoints(md) {
            max-width: 440px;
            font-size: 16px;
            line-height: 24px;
        }

        @include breakpoints(lg) {
            max-width: 510px;
            font-size: 16px;
            line-height: 24px;
        }

        @include breakpoints(xl) {
            margin-top: 32px;
            max-width: 600px;
            font-size: 19px;
            line-height: 28px;
        }
    }

    &__btn {
        text-decoration: none;
        color: white;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        background: linear-gradient(90deg, rgb(49, 194, 167), rgb(17, 100, 192));
        border-radius: 12px;
        padding: 11px 0;

        margin-top: 32px;
        font-family: SBSansDisplay;
        font-size: 16px;
        line-height: 34px;
        font-weight: 500;
        letter-spacing: -0.3px;

        @include breakpoints(sm) {
            padding: 11px 96px;
            margin-top: 32px;
            font-size: 16px;
            line-height: 34px;
        }

        @include breakpoints(md) {
            width: fit-content;
            margin-top: 32px;
            font-size: 26px;
            line-height: 34px;
            font-weight: 500;
            padding: 21px 74px;
        }

        @include breakpoints(lg) {
            margin-top: 32px;
            font-size: 26px;
            line-height: 34px;
            font-weight: 500;
            padding: 21px 74px;
        }

        @include breakpoints(xl) {
            margin-top: 44px;
            padding: 21px 74px;
            font-size: 26px;
            line-height: 34px;
        }
    }

    &__cards {
        position: relative;
        z-index: 3;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        margin-top: 32px;

        @include breakpoints(md) {
            margin-top: 68px;
            flex-direction: row;
            column-gap: 16px;
        }

        @include breakpoints(lg) {
            margin-top: 88px;
            column-gap: 24px;
        }

        &_card {
            flex-basis: 0;
            flex-grow: 1;
            display: flex;
            column-gap: 32px;
            align-items: flex-start;
            padding: 18px 10px 20px 19px;

            background-color: rgba(0, 0, 0, .7);
            border: 1px solid rgba(255, 255, 255, 0.5);
            border-radius: 20px;

            @include breakpoints(md) {
                height: auto;
                flex-direction: column;
                padding: 16px 32px 16px 16px;
                row-gap: 4px;
            }

            &-infoContainer {
                display: flex;
                flex-direction: column;
                row-gap: 4px;
                align-items: flex-start;

                @include breakpoints(md) {
                    height: 100%;
                    flex-direction: column;
                    row-gap: 0;
                    justify-content: space-between;
                }
            }

            &-label {
                font-family: SBSansDisplay;
                font-size: 14px;
                line-height: 24px;
                font-weight: 400;
                opacity: .8;
                width: 52px;


                @include breakpoints(md) {
                    width: fit-content;
                }

                @include breakpoints(md) {
                    font-size: 18px;
                    line-height: 26px;
                }
            }

            &-title {
                font-family: SBSansDisplay;
                font-size: 24px;
                line-height: 32px;
                font-weight: 600;

                @include breakpoints(md) {
                    font-size: 32px;
                    line-height: 38px;
                }
            }

            &-info {
                font-family: SBSansDisplay;
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
                opacity: .8;

                @include breakpoints(md) {
                    margin-top: 22px;
                    font-size: 22px;
                    line-height: 30px;
                }
            }
        }
    }
}
