@import "../../utils/breakpoints";

.footer {
  font-family: SBSansText;
  margin-top: 48px;
  padding: 0 24px 77px 24px;
  display: flex;
  flex-direction: column;

  @include breakpoints(md) {
    margin-top: 68px;
    padding: 0 21px 58px 37px;
  }

  @include breakpoints(lg) {
    margin-top: 72px;
    padding: 0 48px 84px 48px;
  }

  @include breakpoints(xl) {
    margin-top: 88px;
    padding: 0 136px 79px 136px;
  }

  &__separator {
    height: 1px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.12);
  }

  &__content {
    display: flex;
    flex-direction: column;
  
    @include breakpoints(md) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    a:hover {
      transition: 0.2s;
      color: #5ab0ff!important;
    }

    &_license {
      color: rgb(255, 255, 255);
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: -2%;
      margin-top: 32px;

      @include breakpoints(md) {
        max-width: 277px;
      }

      @include breakpoints(lg) {
        max-width: 344px;
      }

      @include breakpoints(xl) {
        max-width: 440px;
      }
    }

    &_contacts {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 24px;

      @include breakpoints(md) {
        margin-left: 96px;
      }

      @include breakpoints(lg) {
        flex-direction: row;
        gap: 48px;
        margin-left: 50px;
      }

      @include breakpoints(xl) {
        margin-left: 194px;
      }

      &_item {
        color: rgba(255, 255, 255, 0.5);
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: -2%;
  
        :last-child {
          color: rgb(255, 255, 255);
          font-size: 16px;
          text-decoration: none;
        }

        @include breakpoints(lg) {
          max-width: 268px;
        }
      }
    }

    &_info {
      color: rgb(255, 255, 255);
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: -2%;
      margin-top: 32px;

      a {
        color: rgb(255, 255, 255);
        text-decoration: none;
      }

      @include breakpoints(xl) {
        margin-top: 44px;
      }
    }

    &_likes {
      color: rgb(255, 255, 255);
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: -2%;
      margin-top: 32px;

      @include breakpoints(md) {
        margin-left: 141px;
        width: 336px;
      }

      @include breakpoints(lg) {
        margin-left: 163px;
        width: 532px;
      }

      @include breakpoints(xl) {
        margin-top: 44px;
        width: 533px;
        margin-left: 402px;
      }

      &_wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 12px;
      }

      &_button {
        border: 1px solid rgb(31, 45, 60);
        border-radius: 50px;
        display: flex;
        gap: 4px;
        justify-content: center;
        align-items: center;
        padding: 4px 10px 4px 10px;
        width: fit-content;
        cursor: pointer;
        transition: .2s;
        
        &:hover {
          border-color: #5ab0ff;
          svg > g > path {
            stroke: #5ab0ff;
          }
        }

        &_active {
          border: 1px solid #5ab0ff;
          border-radius: 50px;
          display: flex;
          gap: 4px;
          justify-content: center;
          align-items: center;
          padding: 4px 10px 4px 10px;
          width: fit-content;
          cursor: pointer;
          transition: .2s;
  
          svg > g > path {
            stroke: #5ab0ff;
          }
        }
      }
    }
  }

  &__feedback {
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    resize: none;
    background: inherit;
    border: 1px solid rgb(31, 45, 60);
    border-radius: 4px;
    width: 100%;
    resize: none;
    padding: 16px;
    height: 115px;

    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.3px;

    margin-top: 20px;

    &_button {
      border-radius: 8px;
      background: rgb(255, 255, 255);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 20px 16px 20px;
      width: fit-content;
      color: rgb(0, 16, 33);
      font-size: 16px;
      font-weight: 600;
      line-height: 90%;
      letter-spacing: -2%;
      margin-top: 24px;
      cursor: pointer;
    }

    &_done {
      border-radius: 16px;
      backdrop-filter: blur(46px);
      background: rgba(9, 40, 72, 0.6);
      padding: 19px 14px;
      display: flex;
      align-items: center;

      svg {
        margin-right: 8px;
      }
    }
  }
}